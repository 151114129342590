import React from 'react'
import classes from './BluewaveFooterColumn.module.scss'
import { UIText } from 'bora-material-ui'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveFooterColumn = ({ title, children }) => (
  <div className={classes['bluewave-footer-column']}>
    <UIText className={classes.title} font={fontBold} align="start">
      {title}
    </UIText>
    {children}
  </div>
)
