import React from 'react'
import classes from './BluewaveInfoBlock.module.scss'
import { UIText } from 'bora-material-ui'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveInfoBlock = ({ title, description, start }) => (
  <div className={classes['bluewave-info-block']}>
    <UIText align={start ? 'start' : 'center'} className={classes.title} font={fontBold} text={title} />
    <UIText align={start ? 'start' : 'center'} className={classes.description} text={description} />
  </div>
)
