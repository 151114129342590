import React from 'react'
import classes from './BluewavePassengerAmenities.module.scss'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import { UIText } from 'bora-material-ui'
import { BluewaveAmentityItem } from './BluewaveAmentityItem'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewavePassengerAmenities = () => (
  <div className={classes['bluewave-passenger-amenities']}>
    <BluewaveContentContainer>
      <div className={classes.container}>
        <UIText className={classes.title} font={fontBold} text={translate(messages.onBoardTitle)} />
        <div className={classes['amenities-container']}>
          <BluewaveAmentityItem iconType="iconSeats_bluewave" description={translate(messages.onBoardService1)} />
          <BluewaveAmentityItem iconType="iconWiFi_bluewave" description={translate(messages.onBoardService2)} />
          <BluewaveAmentityItem iconType="iconBed_bluewave" description={translate(messages.onBoardService3)} />
          <BluewaveAmentityItem iconType="iconWC_bluewave" description={translate(messages.onBoardService4)} />
          <BluewaveAmentityItem iconType="iconFood_bluewave" description={translate(messages.onBoardService5)} />
          <BluewaveAmentityItem
            iconType="iconRefPlugWhite_bluewave"
            description={translate(messages.onBoardService6)}
          />
        </div>
      </div>
    </BluewaveContentContainer>
  </div>
)
