import React from 'react'
import PropTypes from 'prop-types'
import { UIButton, UIIcon, UIImage, UILayout, UIText } from 'bora-material-ui'
import moment from 'moment'
import { head } from 'ramda'
import ReactHtmlParser from 'react-html-parser'
import './NewsStyles.css'

import { FRIENDLY_DATE_FORMAT } from '../../../consts/stuff'
import { olderBrowser } from '../../../utils/browserUtils'
import { getBluewaveStyle, getMaritimeStyle, isBluewave } from '../../../utils/maritimeStyleUtils'
import { THEME } from 'bora-material-ui/themes'
import { isMobile } from '../../Common/components/AppBar'
import moduleStyles from './NewsItem.module.scss'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'
import cn from 'classnames'

const titleStyle = {
  ...(olderBrowser && { marginLeft: '20px' }),
}

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')
const colorEnabled = style.getPropertyValue('--enabled-bg')
const colorPrimaryBase = style.getPropertyValue('--color-primary-base')
const colorPrimaryNormalLighter = style.getPropertyValue('--color-primary-normal-lighter')
const fsBaseStrong = style.getPropertyValue('--fs-base-strong')
const fsBaseMobile = style.getPropertyValue('--fs-base-mobile')

const expandIconType = window.brandProps.theme === THEME.LIINILAEVAD ? 'iconExpandLL' : 'iconChevron'

class NewsItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDescription: false }
  }

  toggleDescription = () => this.setState({ showDescription: !this.state.showDescription })

  render() {
    const { date, title, description, style, locale, imageUrl } = this.props

    const isArabic = locale === 'ar'
    const iconPosition = isArabic ? { top: '15px', left: '0' } : { top: '15px', right: '0' }

    const iconStyles =
      window.brandProps.theme === THEME.LIINILAEVAD
        ? {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(180deg)' : 'rotate(0deg)',
          }
        : {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(270deg)' : 'rotate(90deg)',
          }

    return (
      <UILayout column padding="40px 0" xs={{ padding: '20px 0' }} style={style}>
        <UILayout
          data-testid="news-item-inner"
          j-flex-end
          padding="0 0 15px"
          className={moduleStyles.direction}
          xs={{ column: true, padding: '0 0 10px', position: 'relative' }}
        >
          <UILayout column style={{ maxWidth: '110px' }}>
            <UIText
              color={colorPrimaryBase}
              size={fsBaseStrong}
              xs={{ size: fsBaseMobile, align: 'start' }}
              text={moment(date).format(FRIENDLY_DATE_FORMAT)}
              font={fontBold}
              weight="normal"
              lineHeight="1.5"
            />
          </UILayout>
          <UILayout
            padding={getMaritimeStyle('0 20px')}
            column
            style={titleStyle}
            j-flex-start
            xs={{ padding: getBluewaveStyle('0 30px 0 0', getMaritimeStyle('0')), style: { order: '4' } }}
          >
            <UIText
              color={
                this.state.showDescription ? (isBluewave ? colorPrimaryNormalLighter : colorEnabled) : colorPrimaryBase
              }
              size={fsBaseStrong}
              className={moduleStyles.text}
              xs={{ size: fsBaseMobile }}
              text={title}
              font={fontBold}
              weight="bold"
              lineHeight="1.5"
            />
          </UILayout>
          <UILayout
            column
            j-flex-start
            margin={isArabic ? '0 55px 0 0' : '0 0 0 55px'}
            width="max-content"
            xs={{ position: 'absolute', ...iconPosition }}
          >
            {description && (
              <UIButton
                type="circle"
                width="30px"
                height="30px"
                background={getMaritimeStyle('transparent', 'red')}
                boxShadow={getMaritimeStyle('none')}
                fill={isBluewave ? colorPrimaryBase : colorEnabled}
                xs={{ fill: colorPrimaryBase, background: 'rgba(0,0,0,0)', align: 'right', boxShadow: 'unset' }}
                click={() => this.toggleDescription()}
              >
                <UIIcon type={expandIconType} margin="0 6px" style={iconStyles} />
              </UIButton>
            )}
          </UILayout>
        </UILayout>

        {this.state.showDescription && (
          <div
            className={cn(
              moduleStyles.itemInner,
              window.brandProps.theme === THEME.LIINILAEVAD && moduleStyles.content
            )}
            style={getStyle({ marginLeft: isMobile && 0 })}
          >
            {imageUrl && <UIImage src={imageUrl} width="200px" />}
            {head(
              ReactHtmlParser(
                `<div class="news-content">
                  ${description}
                </div>`
              )
            )}
          </div>
        )}
      </UILayout>
    )
  }
}

NewsItem.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  muiTheme: PropTypes.object,
}

NewsItem.defaultProps = {
  style: {},
}

export default NewsItem
