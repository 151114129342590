import React from 'react'
import classes from './BluewaveKeyAdvantages.module.scss'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import { UIText } from 'bora-material-ui'
import { BluewaveAdvantageItem } from './BluewaveAdvantageItem'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveKeyAdvantages = () => (
  <div className={classes['bluewave-key-advantages']}>
    <BluewaveContentContainer>
      <div className={classes.container}>
        <UIText className={classes.title} font={fontBold} translate={messages.keyAdvanceTitle} />
        <div className={classes['advantages-container']}>
          <BluewaveAdvantageItem
            iconType="iconQuick_bluewave"
            title={translate(messages.keyAdvanceQuickTitle)}
            description={translate(messages.keyAdvanceQuickDesc)}
          />
          <BluewaveAdvantageItem
            iconType="iconBuoy_bluewave"
            title={translate(messages.keyAdvanceCostsTitle)}
            description={translate(messages.keyAdvanceCostsDesc)}
          />
          <BluewaveAdvantageItem
            iconType="iconCargo_bluewave"
            title={translate(messages.keyAdvanceCargoTitle)}
            description={translate(messages.keyAdvanceCargoDesc)}
          />
        </div>
      </div>
    </BluewaveContentContainer>
  </div>
)
