import React from 'react'
import classes from './BluewaveMissionAndVision.module.scss'
import { BluewaveInfoBlock } from '../BluewaveInfoBlock/BluewaveInfoBlock'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

export const BluewaveMissionAndVision = () => (
  <div className={classes['bluewave-mission-and-vision']}>
    <BluewaveContentContainer>
      <div className={classes.container}>
        <BluewaveInfoBlock title={translate(messages.missionTitle)} description={translate(messages.missionDesc)} />
        <BluewaveInfoBlock title={translate(messages.visionTitle)} description={translate(messages.visionDesc)} />
      </div>
    </BluewaveContentContainer>
  </div>
)
