import { UIIcon, UIText } from 'bora-material-ui'
import React from 'react'
import { Link } from 'react-router-dom'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveFooterItem = ({ label, to, iconType }) => {
  if (iconType && to) {
    return (
      <a href={to}>
        <UIIcon type={iconType} />
      </a>
    )
  }

  if (label && to && (to.includes('tel:') || to.includes('mailto:') 
  || to.startsWith('#') || to.includes('https:'))) {
    return (
      <a href={to}>
        <UIText align="start" text={label} font={fontBold} />
      </a>
    )
  }

  if (label && to && !to.includes('tel:')) {
    return (
      <Link to={to}>
        <UIText align="start" text={label} />
      </Link>
    )
  }

  if (label && !to) {
    return <UIText align="start" text={label} />
  }

  return null
}
