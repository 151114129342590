import React from 'react'
import styles from './BluewaveAdvantages.module.scss'
import { BluewaveSlogan } from './BluewaveSlogan/BluewaveSlogan'
import { BluewaveRouteInfo } from './BluewaveRouteInfo/BluewaveRouteInfo'
import { BluewavePassengerAmenities } from './BluewavePassengerAmenities/BluewavePassengerAmenities'
import { BluewaveMissionAndVision } from './BluewaveMissionAndVision/BluewaveMissionAndVision'
import { BluewaveKeyAdvantages } from './BluewaveKeyAdvantages/BluewaveKeyAdvantages'
import { BluewaveAboutUs } from './BluewaveAboutUs/BluewaveAboutUs'

const BluewaveAdvantages = () => {
  return (
    <div className={styles.root}>
      <BluewaveAboutUs />
      <BluewaveKeyAdvantages />
      <BluewaveMissionAndVision />
      <BluewavePassengerAmenities />
      <BluewaveRouteInfo />
      <BluewaveSlogan />
    </div>
  )
}

export default BluewaveAdvantages
