import React from 'react'
import classes from './BluewaveAdvantageItem.module.scss'
import { UIIcon, UIText } from 'bora-material-ui'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveAdvantageItem = ({ iconType, title, description }) => (
  <div className={classes['bluewave-advantage-item']}>
    <UIIcon type={iconType} />
    <UIText className={classes.title} text={title} font={fontBold} />
    <UIText className={classes.description} text={description} />
  </div>
)
