import React, { Fragment } from 'react'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import classes from './BluewaveFooter.module.scss'
import { BluewaveContentContainer } from './BluewaveContentContainer'
import { BluewaveFooterInfo } from './BluewaveFooterInfo/BluewaveFooterInfo'

const BluewaveFooter = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Fragment>
      <BluewaveFooterInfo />
      <UILayout
        className={classes['bluewave-footer']}
        j-flex-center
        center
        style={{
          backgroundColor: '#01588B',
        }}
      >
        <BluewaveContentContainer>
          <UILayout className={classes.container} center j-flex-space-between>
            <UIIcon type="iconFooterLogo_bluewave" />
            <UIText className={classes.text} size="14px" color="#BCE7FC">
              ©{currentYear} Blue Wave Corp.&nbsp;|&nbsp;
              <a className={classes.link} href="https://bora.systems" target="_blank">
                Powered by Bora Systems
              </a>
            </UIText>
          </UILayout>
        </BluewaveContentContainer>
      </UILayout>
    </Fragment>
  )
}

export default BluewaveFooter
