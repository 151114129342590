import React from 'react'
import classes from './BluewaveAboutUs.module.scss'
import { BluewaveInfoBlock } from '../BluewaveInfoBlock/BluewaveInfoBlock'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

export const BluewaveAboutUs = () => (
  <div className={classes['bluewave-about-us']}>
    <BluewaveContentContainer>
      <div className={classes.container}>
        <div className={classes['bluewave-info-block']}>
          <video
            className={classes.video}
            width="542"
            height="307"
            poster="images/bluewave/anim-poster.png"
            controls
            autoplay
          >
            <source src={`${window.brandProps.CMS_API_BASE}/uploads/anim_a4baea1324.mp4`} type="video/mp4" />
          </video>
        </div>
        <BluewaveInfoBlock title={translate(messages.aboutUsTitle)} description={translate(messages.aboutUsDesc)} />
      </div>
    </BluewaveContentContainer>
  </div>
)
