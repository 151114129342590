import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import UIElements from '../../../../consts/UIElements'
import { LanguagePicker } from '../../../Language/components/Selects'
import { changeLocale, getLocale, getMobileMenuStatus } from '../../../../services/user-selections'
import { menuItemsSelector } from '../../../../services/sales-static/selectors'
import { goToPage, showModal } from '../../../../actions'
import s from '../../../Common/components/NavigationBar.module.scss'
import moduleStyles from './BluewaveNavigationBar.module.scss'
import { isMobile, isTablet } from '../../../Common/components/AppBar'
import { styles } from '../../../Common/components/MaritimeNavigationBar'
import cn from 'classnames'
import { BluewaveMobileNavigationMenu } from './BluewaveMobileNavigationMenu/BluewaveMobileNavigationMenu'

const reloadWithLang = (locale = 'en') => {
  const { origin } = window.location

  if (typeof locale !== 'string') {
    return window.location.assign(origin)
  }

  const url = `${origin}/#/${locale}/`
  return window.location.assign(url)
}

class BluewaveNavigationBar extends React.Component {
  state = {
    isMobileMenuVisible: false,
  }

  handleMenuIconClick = () => {
    this.setState({ isMobileMenuVisible: true })
  }

  handleCloseIconClick = () => {
    this.setState({ isMobileMenuVisible: false })
  }

  render() {
    const { locale, changeLocale, menuItems, goToPage } = this.props
    const { isMobileMenuVisible } = this.state

    const style = getComputedStyle(document.body)
    const firstLineItems = menuItems.filter(({ line }) => line === 1)
    const generateItemClickHandler = (link) => (e) => {
      e.preventDefault()
      goToPage(link)

      if (isMobileMenuVisible) {
        this.setState({ isMobileMenuVisible: false })
      }
    }

    const itemFsize = style.getPropertyValue('--fs-base-strong')

    const linkLabelStyles = {
      ...styles.linkLabel,
      color: window.brandProps.muiTheme.palette.primary3Color,
      size: itemFsize,
    }

    if (isTablet) {
      return (
        <Fragment>
          {isMobileMenuVisible && (
            <BluewaveMobileNavigationMenu
              onCloseClick={this.handleCloseIconClick}
              onItemClick={generateItemClickHandler}
              menuItems={menuItems}
            />
          )}

          <UILayout
            j-flex-center
            style={{
              backgroundColor: '#FFF',
              height: '60px',
            }}
          >
            <UILayout center maxWidth={UIElements.APP_WIDTH} className={moduleStyles.rootBar}>
              <UIButton type="icon" width="auto" height="auto" disableRipple click={() => reloadWithLang(locale)}>
                <UIIcon type="iconHeaderLogo_bluewave" />
              </UIButton>

              <UILayout width="auto" center className={moduleStyles.itemsWrap}>
                <LanguagePicker withLabel locale={locale} onChange={changeLocale} />
                <UIIcon height="33px" width="33px" type="iconMenu" onClick={this.handleMenuIconClick} />
              </UILayout>
            </UILayout>
          </UILayout>
        </Fragment>
      )
    }

    return (
      <UILayout
        j-flex-center
        style={{
          backgroundColor: '#FFF',
          height: '60px',
        }}
      >
        <UILayout center maxWidth={UIElements.APP_WIDTH} className={moduleStyles.rootBar}>
          <UIButton type="icon" width="auto" height="auto" disableRipple click={() => reloadWithLang(locale)}>
            <UIIcon type="iconHeaderLogo_bluewave" />
          </UIButton>

          <UILayout width="auto" center className={moduleStyles.itemsWrap}>
            {firstLineItems.map(({ link, id, subject, modal }) => {
              if (modal && isMobile) {
                return null
              }

              if (modal) {
                return (
                  <UILayout key={id} {...styles.linkContainer}>
                    <span onClick={() => showModal(link)}>
                      <UIText {...linkLabelStyles} text={subject} />
                    </span>
                  </UILayout>
                )
              }

              return (
                <UILayout key={id} {...styles.linkContainer} className={s.linkWrap}>
                  <a
                    href={link}
                    className={cn(s.linkElement, moduleStyles.navItem)}
                    onClick={generateItemClickHandler(link)}
                  >
                    <UIText {...linkLabelStyles} size={itemFsize} text={subject} />
                  </a>
                </UILayout>
              )
            })}
          </UILayout>
          <div>
            <LanguagePicker withLabel locale={locale} onChange={changeLocale} />
          </div>
        </UILayout>
      </UILayout>
    )
  }
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
  }),
  { changeLocale, goToPage, showModal }
)(BluewaveNavigationBar)
