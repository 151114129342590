import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'
import classes from './BluewaveSlogan.module.scss'
import messages from '../../../../../consts/messages'

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')

export const BluewaveSlogan = () => (
  <UILayout
    j-flex-center
    center
    className={classes['bluewave-slogan']}
    bg="images/bluewave/slogan-bg.jpg"
    style={{ backgroundSize: 'cover' }}
  >
    <UIText className={classes.text} font={fontBold} translate={messages.slogan} />
  </UILayout>
)
