import React from 'react'
import classes from './BluewaveMobileNavigationMenu.module.scss'
import { UIIcon, UIText } from 'bora-material-ui'
import { BluewaveContentContainer } from '../BluewaveContentContainer'

const renderMenuItem =
  (onItemClick) =>
  ({ link, id, subject, modal }) => {
    if (modal) {
      return null
    }

    return (
      <div key={id}>
        <span onClick={onItemClick(link)}>
          <UIText className={classes['menu-item-text']} text={subject} />
        </span>
      </div>
    )
  }

export const BluewaveMobileNavigationMenu = ({ onCloseClick, onItemClick, menuItems }) => (
  <div className={classes['bluewave-mobile-navigation-menu']}>
    <BluewaveContentContainer>
      <div className={classes.header}>
        <UIIcon height="25px" width="25px" type="iconCross" onClick={onCloseClick} />
      </div>
      <div className={classes['items-container']}>{menuItems.map(renderMenuItem(onItemClick))}</div>
    </BluewaveContentContainer>
  </div>
)
