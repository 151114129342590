import React from 'react'
import classes from './BluewaveRouteInfo.module.scss'
import { UIIcon } from 'bora-material-ui'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import { BluewaveInfoBlock } from '../BluewaveInfoBlock/BluewaveInfoBlock'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

export const BluewaveRouteInfo = () => (
  <div className={classes['bluewave-route-info']}>
    <BluewaveContentContainer>
      <div className={classes.container}>
        <UIIcon className={classes.map} type="iconRouteInfoMap_bluewave" />
        <BluewaveInfoBlock
          start
          title={translate(messages.ourRouteTitle)}
          description={translate(messages.ourRouteDesc)}
        />
      </div>
    </BluewaveContentContainer>
  </div>
)
