import React from 'react'
import { BluewaveContentContainer } from '../BluewaveContentContainer'
import classes from './BluewaveFooterInfo.module.scss'
import { BluewaveFooterColumn } from './BluewaveFooterColumn'
import { BluewaveFooterItem } from './BluewaveFooterItem'
import cn from 'classnames'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

export const BluewaveFooterInfo = () => (
  <div className={classes['bluewave-footer-info']}>
    <BluewaveContentContainer>
      <div className={classes.content}>
        <BluewaveFooterColumn title={translate(messages.services)}>
          <div className={classes.body}>
            <BluewaveFooterItem label={translate(messages.bookTickets)} to="#" />
            <BluewaveFooterItem label={translate(messages.aboutUs)} to="#/es/content/about" />
            <BluewaveFooterItem label={translate(messages.ourFleet)} to="#/es/content/fleet" />
            <div className={classes.icons}>
              <BluewaveFooterItem iconType="iconInstagram_bluewave" to="https://www.instagram.com/bluewave.corp" />
              <BluewaveFooterItem iconType="iconLinkedin_bluewave"
                                  to="https://www.linkedin.com/company/blue-wave-corporation"/>
            </div>
            <BluewaveFooterItem iconType="" to="#" />
          </div>
        </BluewaveFooterColumn>

        <BluewaveFooterColumn title={translate(messages.information)}>
          <div className={classes.body}>
            <BluewaveFooterItem label={translate(messages.news)} to="#/es/content/news" />
            <BluewaveFooterItem label={translate(messages.faq)} to="#/es/content/faq" />
          </div>
        </BluewaveFooterColumn>

        <BluewaveFooterColumn title={translate(messages.schedule)}>
          <div className={classes.body}>
            <BluewaveFooterItem label={translate(messages.schedule)} to="#/es/content/calendar" />
            <BluewaveFooterItem label={translate(messages.routes)} to="#/es/content/routes" />
          </div>
        </BluewaveFooterColumn>

        <BluewaveFooterColumn title={translate(messages.contactUs)}>
          <div className={cn(classes.body, classes.first)}>
            <BluewaveFooterItem label="contacto@bluewavecorporation.com" to="mailto:contacto@bluewavecorporation.com" />
            <BluewaveFooterItem label="+503 2510 5000" to="tel:+50325105000" />
            <BluewaveFooterItem iconType="iconWhatsapp_bluewave" to="https://wa.me/50326080068" />
          </div>
        </BluewaveFooterColumn>
      </div>
    </BluewaveContentContainer>
  </div>
)

export default BluewaveFooterInfo

