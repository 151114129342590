import React from 'react'
import { BluewaveAccordion } from './BluewaveAccordion'
import styles from './BluewaveFaq.module.scss'

const Divider = () => {
  return (
    <div className={styles.divider}></div>
  )
}

const BluewaveFaq = (props) => {
  const { response } = props

  const nodeContainer = document.createElement('div')
  nodeContainer.innerHTML = response.body

  for (const element of nodeContainer.getElementsByTagName('*')) {
    element.removeAttribute('style')
  }

  const nodeElements = nodeContainer.getElementsByTagName('p')

  const listFaq = []

  for (let i = 0; i < nodeElements.length; i += 3) {
    const title = nodeElements[i].innerHTML
    const body = nodeElements[i + 1].innerHTML
    listFaq.push({ title, body })
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>{response.subject}</div>

      {listFaq.map((faq, index) => (
        <div key={index}>
          {index === 0 && <Divider />}
          <BluewaveAccordion
            title={
              <div dangerouslySetInnerHTML={{ __html: faq.title }}></div>
            }
            body={
              <div dangerouslySetInnerHTML={{ __html: faq.body }}></div>
            }
          />
          <Divider />
        </div>
      ))}
    </div>
  )
}

export default BluewaveFaq
