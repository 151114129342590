import React from 'react'
import classes from './BluewaveAmentityItem.module.scss'
import { UIIcon, UIText } from 'bora-material-ui'

export const BluewaveAmentityItem = ({ iconType, description }) => (
  <div className={classes['bluewave-amentity-item']}>
    <UIIcon type={iconType} />
    <UIText className={classes.description} text={description} align="start" />
  </div>
)
