import React, { useState } from 'react'
import cn from 'classnames'
import { BluewaveChevronUpIcon } from './BluewaveChevronUpIcon'
import styles from './BluewaveAccordion.module.scss'

export const BluewaveAccordion = (props) => {
  const { title, body } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div className={styles.content}>
        <div
          onClick={() => setIsOpen(prev => !prev)}
          className={cn(styles.title, {
            [styles.opened]: isOpen
          })}
        >
          <div>{title}</div>
          <BluewaveChevronUpIcon />
        </div>

        {isOpen && (
          <div className={styles.body}>{body}</div>
        )}
      </div>
    </div>
  )
}
