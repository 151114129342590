import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UIImage, UILayout } from 'bora-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReactHtmlParser from 'react-html-parser'
import { addIndex, compose, head, isEmpty, join, map, path, split, values, without } from 'ramda'
import { NotFoundContent, PageTitle } from '../Common'
import { getShipDetails } from '../../../services/sales-static/api'
import { getLocale } from '../../../services/user-selections'
import { getPageTitleBySlug } from '../../../services/sales-static/selectors'
import './ShipParametersStyles.css'
import { olderBrowser } from '../../../utils/browserUtils'
import { mediumImagesPath, mediumImagesTail, smallImagesPath } from '../../../services/sales-static/utils'

export const cmsBase = '/cms-images'

const ieImageStyle = {
  ...(olderBrowser && { width: '450px', display: 'block' }),
}

const styles = {
  width100: { width: '100%' },
  width80: { width: '80%' },
  minHeight650: { minHeight: '650px' },
  fleetItemImage: { aspectRatio: '4/3', objectFit: 'fill', maxWidth: '450px' },
  maxWidth50: { maxWidth: '50%' },
  additionalImage: { width: '100%', margin: '20px 0 0 0' },
  additionalImageBG: { backgroundRepeat: 'no-repeat', backgroundSize: 'contain' },
}

const xsStyles = {
  marginLeft20: { margin: '20px 0 0 0' },
  maxWidth100: { style: { maxWidth: '100%' } },
  marginLeft16Column: { column: true, margin: '16px 0 0 0' },
}

class ShipInfoContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      parametersContent: null,
      mainImage: null,
      additionalImages: [],
    }
  }

  componentDidMount() {
    const { match, locale } = this.props
    getShipDetails(match.params.slug, locale)
      .then((res) => {
        const shipInfo = head(res)
        if (!shipInfo) throw new Error('Vessel info is missing')
        const title = path(['name'], shipInfo)

        const richTextContent = path(['body'], shipInfo)
        const parametersContent = head(ReactHtmlParser(`<div>${richTextContent}</div>`))

        const mainImagePath = mediumImagesPath(shipInfo) || smallImagesPath(shipInfo)
        const mainImageUrl = `${cmsBase}${mainImagePath}`
        const additionalImages = compose(
          map((i) => `${cmsBase}${i}`),
          mediumImagesTail
        )(shipInfo)

        this.setState({ title, parametersContent, mainImage: mainImageUrl, additionalImages })
      })
      .catch(() => {
        this.setState({ parametersContent: [] })
      })
  }

  generateAdditionalImages = addIndex(map)((imageUrl, i) => (
    <UIImage display-if={imageUrl} key={i} src={imageUrl} style={styles.additionalImage} />
  ))

  render() {
    const contentNotFetched = isEmpty(this.state.parametersContent)

    return (
      <UILayout>
        <NotFoundContent display-if={contentNotFetched} />
        <UILayout column display-if={!contentNotFetched}>
          <PageTitle title={this.props.sectionTitle} secondaryTitle={this.state.title} />
          {this.state.parametersContent ? (
            <UILayout data-testid="fleet-item" margin="38px 0 0 0" xs={xsStyles.marginLeft16Column}>
              <UILayout
                minHeight="204px"
                bg={this.state.mainImage}
                style={styles.additionalImageBG}
                width="100%"
                xs={1}
                xl={0}
              />
              <UILayout
                data-testid="fleet-item-figure"
                column
                margin="0 20px 0 0"
                style={{ ...styles.width100, ...styles.minHeight650 }}
                xs={xsStyles.marginLeft20}
              >
                {React.cloneElement(this.state.parametersContent, { className: 'ship-parameters' })}
              </UILayout>
              <UILayout data-testid="fleet-item-images" column xs={xsStyles.maxWidth100}>
                <UIImage
                  src={this.state.mainImage}
                  style={{ ...ieImageStyle, ...styles.fleetItemImage }}
                  xs={0}
                  xl={1}
                />
                {this.generateAdditionalImages(this.state.additionalImages)}
              </UILayout>
            </UILayout>
          ) : (
            <UILayout j-flex-center margin="60px">
              <CircularProgress />
            </UILayout>
          )}
        </UILayout>
      </UILayout>
    )
  }
}

ShipInfoContent.propTypes = {
  match: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
}

ShipInfoContent.defaultProps = {
  sectionTitle: '',
}

const trimLocaleAndVesselName = ({ url, params }) => {
  const slugsToTrim = values(params)
  return compose(join('/'), without(slugsToTrim), split('/'))(url)
}

const mapStateToProps = (state, props) => ({
  locale: getLocale()(state),
  sectionTitle: compose(getPageTitleBySlug, trimLocaleAndVesselName)(props.match)(state),
})

export default connect(mapStateToProps)(ShipInfoContent)
